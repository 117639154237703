/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
        $(document).ready(function() {

          if(window.location.hash) {
            //alert('Tiene hashtag');
            var destino = window.location.hash;
            var offset_header = 60;
              if ($(window).width() < 768) {
                offset_header = 55;
              }
              setTimeout( function() {
                $('html, body').animate({
                scrollTop: ($( destino ).offset().top - offset_header + 2)
                }, 500);
              }, 500);
          }

          $('body').addClass('page-loaded');

          $('#miniactualidad').mCustomScrollbar({
            scrollInertia: 100
          });

          // Abrir elementos inline
          $('.show-menu').click( function(e) {
            e.preventDefault();
            $(this).toggleClass('active');
            $('#menumobile').toggleClass('mostrar');

            if ($('#miniactualidad').is(":visible"))
            {
              $('#miniactualidad').removeClass('is-visible');
            } 
          });

          $('.show-miniactualidad').click( function(e) {
            e.preventDefault();

            if ($('#menumobile').hasClass('mostrar'))
            {
              $('#menumobile').toggleClass('mostrar');
              $('.show-menu').toggleClass('active');
            } 

            $('#miniactualidad').addClass('is-visible');
          });

          $('.close-miniactualidad').click( function(e) {
            e.preventDefault();
            $('#miniactualidad').removeClass('is-visible');
          });

          new WOW().init();
          
        });

        var header_mini_prev = false;

        $(window).on('scroll', function(){
          var scrollTop = $(window).scrollTop();
          if ( scrollTop > 70 )
          {
            $('body').addClass('header-mini');
            if (!header_mini_prev) {
              $('body').scrollspy({ offset: $('.navbar:eq(0)').outerHeight(), target: '.menu-menu-container' });
            }
            header_mini_prev = true;
          }
          else
          {
            
            $('body').removeClass('header-mini');
            if (header_mini_prev) {
              $('body').scrollspy({ offset: $('.navbar:eq(0)').outerHeight(), target: '.menu-menu-container' });
            }
            header_mini_prev = false;

          }
        });

        $('.scroll-top').click(function(){
            $('html, body').animate({scrollTop: 0}, 750);
            return false;
        });

      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {

        new WOW().init();

        // Calcular altura bloques
        function resizeContent()
        {
          var _window_h = $(window).outerHeight();
          var altura = _window_h - $('.navbar:eq(0)').outerHeight();
          var altIntro = parseFloat(altura * 0.24).toFixed(8);
          var altContent = parseFloat(altura * 0.76).toFixed(8);
          var altTecnologias = parseFloat($('.tecnologias').outerHeight()).toFixed(8);
          var target1 = $('.recursos');
          var target2 = target1.find('.panel-grid');
          var target3 = target2.find('.panel-grid-cell');
          var target4 = target3.find('.so-panel');
          var target5 = target4.find('.so-widget-sow-editor');
          var target6 = target5.find('.textwidget');
          var estrategia = $('.estrategia');
          var estrategiaIntro = $('.estrategia').find('.intro');
          var estrategiaContent = $('.estrategia').find('.negocio, .tecnologias');

          target1.css('height' , altura);
          target2.css('height' , altura);
          target3.css('min-height' , altura);
          target4.css('min-height' , altura);
          target5.css('min-height' , altura);
          target6.css('min-height' , altura);
          //estrategia.css('height' , altura);
          estrategiaIntro.css('height' , altIntro);
          estrategiaContent.css('height' , altContent);
        }

        // JavaScript to be fired on the home page
        $(document).ready(function() {

          $('.main').css('overflow-x', 'hidden');

          // Menú espía
          $('body').scrollspy({ offset: 60, target: '.menu-menu-container' });
          $('#menu-menu li:eq(0)').addClass('active');

          $('li.link > a').each(function(i,a){
            var $a = $(a);
            var href = $a.attr('href');
            href = href.replace('/', '');

            $a.attr('href',href);

            // Now refresh scrollspy
            // $('[data-spy="scroll"]').each(function (i,spy) {
            //    $(spy).scrollspy('refresh');
            // });

          });

          // ScrollTo con animación
          $('li.link a').click(function(){
              var $this = $(this);
              var targetScroll = $.attr(this, 'href');
              var targetScroll2 = targetScroll.replace('/', '');

              var offset_header = 60;
              if ($(window).width() < 768) {
                offset_header = 55;
              } else if ($this.index('li.link > a') === 0) {
                offset_header = 96;
              }

              $('html, body').animate({
                  scrollTop: ($( targetScroll2 ).offset().top - offset_header + 2)
              }, 500, function() {
                if ($this.index('li.link > a') === 0) {
                  $this.closest('li').addClass('active');
                }
              });

              if ($(this).closest('ul').is("#menu-menu"))
              {
                $( '.show-menu' ).trigger( 'click' );
              } 

              //$( '.show-menu' ).trigger( 'click' );
              return false;
          });


          // Redimensionamos ciertas secciones de la home
          resizeContent();

          // Posicionamos correctamente los bloques de Recursos
          $('.recursos').addClass('left');

          $('.show-panel-1').click( function(e) {
            e.preventDefault();
            $('.recursos').toggleClass('left');
          });

          $('.show-panel-2').click( function(e) {
            e.preventDefault();
            $('.recursos').toggleClass('left');
          });

          $('.show-panel-3').click( function(e) {
            e.preventDefault();
            $('.recursos').toggleClass('extraleft');
          });

          $('.show-panel-4').click( function(e) {
            e.preventDefault();
            $('.recursos').toggleClass('extraleft');
          });
          
        });

        $(window).on('resize', function(){
          resizeContent();
        });

      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    },
    'e2kbrokernet': {
      init: function() {
        // JavaScript to be fired on the about us page
        $(document).ready(function() {

          $('.menu-menu-container > ul > li > a').eq(6).addClass('is-inview');
          $('.textwidget').addClass('row-eq-height');
          
        });
      }
    },
    'actualidad': {
      init: function() {
        // JavaScript to be fired on the about us page
        $(document).ready(function() {

          $('.menu-menu-container > ul > li > a').eq(7).addClass('is-inview');

          setTimeout(function() {
            $('#ff-admin-info').hide();
          }, 1000); 
          
        });
      }
    },
    'single': {
      init: function() {
        // JavaScript to be fired on the about us page
        $(document).ready(function() {

          $('.menu-menu-container > ul > li > a').eq(7).addClass('is-inview');
          
        });
      }
    },
    'contacto': {
      init: function() {
        // JavaScript to be fired on the about us page
        $(document).ready(function() {

          $('.menu-menu-container > ul > li > a').eq(8).addClass('is-inview');
          $('.textwidget').addClass('row-eq-height');
          
        });
      }
    }
  };



  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
